import { StylesConfig } from 'react-select';

import colors from 'styles/colors';

import { OptionTypeBase } from './index';

export const customStyles: StylesConfig<OptionTypeBase, true> = {
  control: provided => ({
    ...provided,
    height: '2.25rem',
    minHeight: '2.25rem',
    margin: '0',
    fontSize: '.8rem',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 0,
    borderRadius: '.5rem',
    boxShadow: 'none',
    '&:hover': {
      border: 0,
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    width: '100%',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 .5rem',
    color: colors.gray100,
    flexWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '.8rem',
    width: '100%',
  }),
  menu: provided => ({
    ...provided,
    fontSize: '.8rem',
    margin: '.25rem 0',
    zIndex: 19,
    borderRadius: '.5rem',
    width: '100%',
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.gray100,
    fontSize: '.8rem',
  }),
  input: provided => ({
    ...provided,
    color: colors.gray500,
    fontSize: '.8rem',
  }),
  multiValue: provided => ({
    ...provided,
    color: colors.gray500,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: ' 0 .5rem 0 0',
    color: colors.gray100,
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: ' 0 .5rem 0 0',
    color: colors.gray100,
  }),
  option: provided => ({
    ...provided,
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};
