import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPutEditCategoryRequest from 'services/httpClient/request/category/IPutEditCategoryRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { categoryActions } from '../index';
import { IEditCategoryRequestAction } from '../reducers/edit/request';

function* edit(action: PayloadAction<IEditCategoryRequestAction>) {
  try {
    const body: IPutEditCategoryRequest = {
      name: action.payload.data.name.trim(),
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admins/categories/${action.payload.data.id}`,
      body,
    );

    yield put(
      categoryActions.editSuccess({
        id: action.payload.data.id,
        name: action.payload.data.name,
      }),
    );

    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(categoryActions.editFailure());
  }
}

export default edit;
