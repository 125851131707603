import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import ICategory from 'models/Category';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { categoryActions } from 'store/slices/category';
import categorySelectors from 'store/slices/category/selectors';

import CategoryForm, { IFormData } from '../Form';

export interface IRefProps {
  open(data: ICategory): void;
}

const CategoryEdit: ForwardRefRenderFunction<IRefProps> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();
  const toast = useToast();

  const isLoading = useReduxSelector(categorySelectors.editIsLoading);

  const categoryFormRef = useRef<FormikProps<IFormData>>(null);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const [categoryValues, setCategoryValues] = useState<ICategory>(
    {} as ICategory,
  );
  const [hideModal, setHideModal] = useState<boolean>(false);

  const openModal = useCallback((data: ICategory): void => {
    setHideModal(false);
    setCategoryValues(data);
    componentModalBaseRef.current?.open();
    setTimeout(() => {
      categoryFormRef.current?.setValues({
        name: data.name,
      });
    }, 250);
  }, []);

  const closeModal = useCallback((): void => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleEditCategory = useCallback(
    (data: IFormData): void => {
      categoryFormRef.current?.setErrors({});
      reduxDispatch(
        categoryActions.editRequest({
          data: {
            id: categoryValues.id,
            name: data.name,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              closeModal();
            },
          },
        }),
      );
    },
    [categoryValues.id, closeModal, reduxDispatch, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Editar categoria</Title>
        <CategoryForm
          isLoading={isLoading}
          onClose={closeModal}
          onSubmit={handleEditCategory}
          ref={categoryFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(CategoryEdit);
