import Tables from 'styles/tables';

import { CategoryItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <CategoryItemsHeader>
      <Tables.ItemHeader>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>Qtde. produtos vinculados</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </CategoryItemsHeader>
  );
};

export default ItemHeader;
