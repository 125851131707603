import { forwardRef, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';
import Forms from 'styles/forms';

export interface ICategoryFilterData {
  name: string;
}

interface ICategoriesFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSubmit: (data: ICategoryFilterData) => void;
}

const CategoryFilter: React.ForwardRefRenderFunction<
  FormikProps<ICategoryFilterData>,
  ICategoriesFilterProps
> = ({ isLoading, onClear, onSubmit }, ref) => {
  const filterFormikRef = useRef<FormikProps<ICategoryFilterData>>(
    {} as FormikProps<ICategoryFilterData>,
  );

  useImperativeHandle(ref, () => ({
    ...filterFormikRef.current,
  }));

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      innerRef={filterFormikRef}
      onSubmit={onSubmit}
    >
      {({ errors, handleChange, values }) => (
        <Forms.Content>
          <Forms.Grid
            gridTemplateColumns="25rem repeat(2, 2rem)"
            marginBottom="0"
          >
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              height="2rem"
              label="Nome"
              onChange={handleChange('name')}
              value={values.name}
            />
            <ComponentButtonBase
              backgroundColor={colors.gray300}
              disabled={isLoading}
              height="2rem"
              onClick={onClear}
              title="Limpar pesquisa"
            >
              <FiX size={18} />
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              height="2rem"
              isLoading={isLoading}
              title="Pesquisar"
              type="submit"
            >
              <FiSearch size={18} />
            </ComponentButtonBase>
          </Forms.Grid>
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(CategoryFilter);
