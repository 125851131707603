import createFailure from './create/failure';
import createRequest from './create/request';
import createSuccess from './create/success';
import editFailure from './edit/failure';
import editRequest from './edit/request';
import editSuccess from './edit/success';
import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccessWithData from './getAll/successWithData';
import getAllSuccessWithoutData from './getAll/successWithoutData';
import getAllToSelectFailure from './getAllToSelect/failure';
import getAllToSelectRequest from './getAllToSelect/request';
import getAllToSelectSuccess from './getAllToSelect/success';

const reducers = {
  createFailure,
  createRequest,
  createSuccess,
  editFailure,
  editRequest,
  editSuccess,
  getAllFailure,
  getAllRequest,
  getAllSuccessWithData,
  getAllSuccessWithoutData,
  getAllToSelectFailure,
  getAllToSelectRequest,
  getAllToSelectSuccess,
};

export default reducers;
