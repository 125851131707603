import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

export interface IEditCategoryRequestAction {
  data: {
    id: number;
    name: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function editRequest(
  draft: Draft<ICategoryData>,
  _: PayloadAction<IEditCategoryRequestAction>,
) {
  draft.edit.config.isLoading = true;
}

export default editRequest;
