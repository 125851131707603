import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

export interface ICreateCategoryRequestAction {
  data: {
    name: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function createRequest(
  draft: Draft<ICategoryData>,
  _: PayloadAction<ICreateCategoryRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
