import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllCategoryResponse from 'services/httpClient/responses/category/IGetAllCategoryResponse';

import { ICategoryData } from '../..';

function getAllToSelectSuccess(
  draft: Draft<ICategoryData>,
  action: PayloadAction<IGetAllCategoryResponse>,
) {
  draft.getAllToSelect.config.isLoading = false;
  draft.getAllToSelect.list = action.payload.list.map(category => ({
    label: category.name,
    value: category.id,
  }));
}

export default getAllToSelectSuccess;
