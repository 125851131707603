import { Draft } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

function getAllFailure(draft: Draft<ICategoryData>) {
  draft.getAll.config.errorMessage = 'Não foi possível carregar as categorias';
  draft.getAll.config.isLoading = false;
}

export default getAllFailure;
