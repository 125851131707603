import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostCreateProductRequest from 'services/httpClient/request/product/IPostCreateProductRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { productActions } from '../index';
import { ICreateProductRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<ICreateProductRequestAction>) {
  try {
    const body: IPostCreateProductRequest = {
      description: action.payload.data.description || undefined,
      ean: action.payload.data.ean,
      images: action.payload.data.images.map(image => ({
        isActive: true,
        sort: image.sort,
        url: image.url,
      })),
      name: action.payload.data.name,
      manufacturerId: action.payload.data.manufacturerId,
      otherInfo: action.payload.data.otherInfo || undefined,
      sku: action.payload.data.sku,
      amount: action.payload.data.amount
        ? Number(
            Number(action.payload.data.amount.replace(',', '.')).toFixed(2),
          )
        : undefined,
      categoryIds: action.payload.data.categoryIds,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admins/products',
      body,
    );

    yield put(productActions.createSuccess());
    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.createFailure());
  }
}

export default create;
