import { all, takeLatest } from 'redux-saga/effects';

import { categoryActions } from '../index';
import create from './create';
import edit from './edit';
import getAll from './getAll';
import getAllToSelect from './getAllToSelect';

const categorySagas = all([
  takeLatest(categoryActions.createRequest, create),
  takeLatest(categoryActions.editRequest, edit),
  takeLatest(categoryActions.getAllRequest, getAll),
  takeLatest(categoryActions.getAllToSelectRequest, getAllToSelect),
]);

export default categorySagas;
