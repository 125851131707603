import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

interface IEditSuccessAction {
  id: number;
  name: string;
}

function editSuccess(
  draft: Draft<ICategoryData>,
  action: PayloadAction<IEditSuccessAction>,
) {
  draft.edit.config.isLoading = false;
  draft.edit.config.isLoading = false;
  const categoryIndex = draft.getAll.list.findIndex(
    category => category.id === action.payload.id,
  );
  const categoryToEdit = draft.getAll.list[categoryIndex];
  draft.getAll.list[categoryIndex] = {
    name: action.payload.name,
    id: categoryToEdit.id,
    quantityProductsAssociated: categoryToEdit.quantityProductsAssociated,
  };
}

export default editSuccess;
