import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface IEditProductImageRequestAction {
  isActive: boolean;
  sort: number;
  url: string;
}

export interface IEditProductRequestAction {
  data: {
    amount?: string;
    categoryIds: number[];
    description?: string;
    ean: string;
    id: number;
    images: IEditProductImageRequestAction[];
    manufacturerId: number;
    name: string;
    otherInfo?: string;
    sku: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function editRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<IEditProductRequestAction>,
) {
  draft.edit.config.isLoading = true;
}

export default editRequest;
