import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

export interface IGetAllCategorySuccessWithoutDataAction {
  message: string;
}
function getAllSuccessWithoutData(
  draft: Draft<ICategoryData>,
  action: PayloadAction<IGetAllCategorySuccessWithoutDataAction>,
) {
  draft.getAll.config.emptyMessage = action.payload.message;
  draft.getAll.config.isLoading = false;
}

export default getAllSuccessWithoutData;
