import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IGetAllProductListResponse } from 'services/httpClient/responses/product/IGetAllProductResponse';

import { IProductData } from '../../index';

export interface IGetAllProductSuccessWithDataAction {
  list: IGetAllProductListResponse[];
  totalPages: number;
}

function getAllSuccessWithData(
  draft: Draft<IProductData>,
  action: PayloadAction<IGetAllProductSuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.config.pagination.totalPages = action.payload.totalPages;
  draft.getAll.list = action.payload.list.map(product => ({
    isActive: product.isActive,
    barcode: product.barcode,
    companyId: product.companyId,
    description: product.description || undefined,
    id: product.id,
    images: product.images,
    manufacturer: {
      name: product.manufacturer.name,
    },
    sku: product.sku,
    manufacturerId: product.manufacturerId,
    name: product.name,
    amount: product.amount ? formatters.money(product.amount) : undefined,
    otherInfo: product.otherInfo || undefined,
    categories: product.categories,
  }));
}

export default getAllSuccessWithData;
