import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import ProductForm, { IProductFormData, IProductFormRefProps } from '../Form';

export interface IProductCreateRefProps {
  close(): void;
  open(): void;
}

interface IProductCreateProps {
  reloadProducts(): void;
}

const ProductCreate: React.ForwardRefRenderFunction<
  IProductCreateRefProps,
  IProductCreateProps
> = ({ reloadProducts }, ref) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(productSelectors.createIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const productFormRef = useRef<IProductFormRefProps>(null);

  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreateProduct = useCallback(
    async (data: IProductFormData) => {
      productFormRef.current?.formik.setErrors({});
      reduxDispatch(
        productActions.createRequest({
          data: {
            ean: data.ean,
            description: data.description || undefined,
            manufacturerId: data.manufacturer?.value as number,
            name: data.name,
            images: productFormRef.current?.uploadedImages || [],
            sku: data.sku,
            otherInfo: data.otherInfo || undefined,
            amount: data.amount,
            categoryIds: data.categories
              ? data.categories.map(category => category.value)
              : [],
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              reloadProducts();
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, reloadProducts, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''} maxWidth={45}>
        <Title>Novo produto</Title>
        <ProductForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleCreateProduct}
          ref={productFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ProductCreate);
