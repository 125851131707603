import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductData } from '../../index';

interface ICreateProductImageRequestAction {
  isActive: boolean;
  sort: number;
  url: string;
}

export interface ICreateProductRequestAction {
  data: {
    amount?: string;
    categoryIds: number[];
    description?: string;
    ean: string;
    images: ICreateProductImageRequestAction[];
    manufacturerId: number;
    name: string;
    otherInfo?: string;
    sku: string;
  };
  functions: {
    closeForm: () => void;
    error: (err: any) => void;
    success: (message: string) => void;
  };
}

function createRequest(
  draft: Draft<IProductData>,
  _: PayloadAction<ICreateProductRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
