import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetAllCategoryRequest from 'services/httpClient/request/category/IGetAllCategoryRequest';
import IGetAllCategoryResponse from 'services/httpClient/responses/category/IGetAllCategoryResponse';

import { categoryActions } from '../index';
import { IGetAllCategoryRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllCategoryRequestAction>) {
  try {
    const params: IGetAllCategoryRequest = {
      name: action.payload.data.name || undefined,
      page: action.payload.data.page,
    };
    const response: AxiosResponse<IGetAllCategoryResponse> = yield call(
      httpClient.get,
      'admins/categories',
      { params },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        categoryActions.getAllSuccessWithoutData({
          message: 'Nenhuma categoria encontrada',
        }),
      );
      return;
    }
    yield put(
      categoryActions.getAllSuccessWithData({
        list: response.data.list,
        totalPages: response.data.totalPages || 1,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(categoryActions.getAllFailure());
  }
}

export default getAll;
