export default {
  auth: {
    signIn: '/',
    forgotPassword: '/recuperar-senha',
    resetPassword: '/redefinir-senha',
  },
  category: {
    list: '/categorias',
  },
  product: {
    list: '/produtos',
    import: '/produtos/importar',
  },
  manufacturer: {
    list: '/fabricantes',
    import: '/fabricantes/importar',
  },
  user: {
    list: '/usuarios',
  },
  settings: '/configuracoes',
  error: '/erro-500',
};
