import createIsLoading from './createIsLoading';
import editIsLoading from './editIsLoading';
import getAllEmptyMessage from './getAllEmptyMessage';
import getAllErrorMessage from './getAllErrorMessage';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPagination from './getAllPagination';
import getAllToSelect from './getAllToSelect';
import getAllToSelectIsLoading from './getAllToSelectIsLoading';
import getAllToSelectList from './getAllToSelectList';

const categorySelectors = {
  createIsLoading,
  editIsLoading,
  getAllEmptyMessage,
  getAllList,
  getAllIsLoading,
  getAllErrorMessage,
  getAllPagination,
  getAllToSelectList,
  getAllToSelect,
  getAllToSelectIsLoading,
};

export default categorySelectors;
