import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IPagination from 'interfaces/IPagination';
import ICategory from 'models/Category';

import reducers from './reducers';

export interface ICategoryData {
  create: {
    config: {
      isLoading: boolean;
    };
  };
  edit: {
    config: {
      isLoading: boolean;
    };
  };
  getAll: {
    config: {
      emptyMessage: string;
      errorMessage: string;
      isLoading: boolean;
      pagination: IPagination;
    };
    list: ICategory[];
  };
  getAllToSelect: {
    config: {
      isLoading: boolean;
    };
    list: IComponentSelectProps[];
  };
}

const initialState: ICategoryData = {
  create: {
    config: {
      isLoading: false,
    },
  },
  edit: {
    config: {
      isLoading: false,
    },
  },
  getAll: {
    config: {
      emptyMessage: 'Nenhuma categoria encontrada',
      errorMessage: '',
      isLoading: false,
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    list: [],
  },
  getAllToSelect: {
    config: {
      isLoading: false,
    },
    list: [],
  },
};

const categorySlice = createSlice({
  name: '@category',
  initialState,
  reducers,
});

export const categoryActions = categorySlice.actions;
export const categoryReducers = categorySlice.reducer;
