import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { categoryActions } from 'store/slices/category';
import categorySelectors from 'store/slices/category/selectors';

import CategoryForm, { IFormData } from '../Form';

export interface IRefProps {
  close(): void;
  open(): void;
}

interface IProps {
  onReload(): void;
}

const CategoryCreate: ForwardRefRenderFunction<IRefProps, IProps> = (
  { onReload },
  ref,
) => {
  const reduxDispatch = useReduxDispatch();
  const toast = useToast();

  const isLoading = useReduxSelector(categorySelectors.createIsLoading);

  const categoryFormRef = useRef<FormikProps<IFormData>>(null);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const [hideModal, setHideModal] = useState<boolean>(false);

  const openModal = useCallback((): void => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback((): void => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreateCategory = useCallback(
    (data: IFormData): void => {
      categoryFormRef.current?.setErrors({});
      reduxDispatch(
        categoryActions.createRequest({
          data: {
            name: data.name,
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              onReload();
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, onReload, reduxDispatch, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <Title>Nova categoria</Title>
        <CategoryForm
          isLoading={isLoading}
          onClose={closeModal}
          onSubmit={handleCreateCategory}
          ref={categoryFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(CategoryCreate);
