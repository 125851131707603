import { FiEdit } from 'react-icons/fi';

import ICategory from 'models/Category';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { CategoryItemsBody } from '../styles';

interface IItemBodyProps {
  category: ICategory;
  openEdit: (data: ICategory) => void;
  position: number;
}

const ItemBody = ({
  category,
  openEdit,
  position,
}: IItemBodyProps): JSX.Element => {
  return (
    <CategoryItemsBody addColorRow={!!(position % 2)}>
      <Tables.ItemBody>{category.id}</Tables.ItemBody>
      <Tables.ItemBody>{category.name}</Tables.ItemBody>
      <Tables.ItemBody>{category.quantityProductsAssociated}</Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => openEdit(category)}
            title="Editar categoria"
          >
            <FiEdit color={colors.cyan900} size={18} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </CategoryItemsBody>
  );
};

export default ItemBody;
