import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IPostCreateCategoryRequest from 'services/httpClient/request/category/IPostCreateCategoryRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { categoryActions } from '../index';
import { ICreateCategoryRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<ICreateCategoryRequestAction>) {
  try {
    const body: IPostCreateCategoryRequest = {
      name: action.payload.data.name.trim(),
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admins/categories',
      body,
    );
    yield put(categoryActions.createSuccess());
    action.payload.functions.closeForm();
    action.payload.functions.success(response.data.message);
  } catch (err) {
    action.payload.functions.error(err);
    yield put(categoryActions.createFailure());
  }
}

export default create;
