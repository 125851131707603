import { useCallback, useEffect, useState } from 'react';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import ReactSelect, { GroupBase, Props as SelectProps } from 'react-select';

import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container, Error, Label, SelectContainer } from '../styles';
import { customStyles } from './styles';

export type OptionTypeBase = {
  label: string;
  value: string | number | boolean;
};

interface IComponentSelectMultiProps
  extends SelectProps<OptionTypeBase, true, GroupBase<OptionTypeBase>> {
  errorMessage?: string;
  gridColumn?: string;
  hasError?: boolean;
  height?: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
  onLoadOptions?(setLoading: (stateLoading: boolean) => void): Promise<void>;
  options?: IComponentSelectProps<number | string | boolean>[];
  placeholder?: string;
  title?: string;
}

const ComponentSelectMulti = ({
  errorMessage,
  gridColumn,
  hasError = false,
  height,
  icon: IconInput,
  label,
  onLoadOptions,
  options,
  placeholder,
  title,
  ...rest
}: IComponentSelectMultiProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSetLoading = useCallback((stateLoading: boolean) => {
    setIsLoading(stateLoading);
  }, []);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    if (onLoadOptions) {
      onLoadOptions(onSetLoading);
    }
  }, [onLoadOptions, onSetLoading]);

  return (
    <Container gridColumn={gridColumn}>
      <ComponentIsVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentIsVisible>

      <SelectContainer
        hasIcon={!!IconInput}
        height={height}
        isErrored={hasError}
        isFocused={isFocused}
        title={title}
      >
        {IconInput && <IconInput size={18} />}
        <ReactSelect
          isDisabled={isLoading}
          isLoading={isLoading}
          isMulti
          loadingMessage={() => 'Buscando'}
          noOptionsMessage={() => 'Sem opções'}
          onBlur={handleSelectBlur}
          onFocus={handleSelectFocus}
          options={options}
          placeholder={isLoading ? 'BUSCANDO' : placeholder}
          styles={customStyles}
          {...rest}
        />

        <ComponentIsVisible when={hasError}>
          <Error title={errorMessage as string}>
            <FiAlertCircle color={colors.red500} size={18} />
          </Error>
        </ComponentIsVisible>
      </SelectContainer>
    </Container>
  );
};

ComponentSelectMulti.defaultProps = {
  label: undefined,
  options: undefined,
  title: undefined,
  placeholder: undefined,
  onLoadOptions: undefined,
};

export default ComponentSelectMulti;
