import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllCategoryListResponse } from 'services/httpClient/responses/category/IGetAllCategoryResponse';

import { ICategoryData } from '../../index';

export interface IGetAllCategorySuccessWithDataAction {
  list: IGetAllCategoryListResponse[];
  totalPages: number;
}

function getAllSuccessWithData(
  draft: Draft<ICategoryData>,
  action: PayloadAction<IGetAllCategorySuccessWithDataAction>,
) {
  draft.getAll.config.errorMessage = '';
  draft.getAll.config.emptyMessage = '';
  draft.getAll.config.isLoading = false;
  draft.getAll.config.pagination.totalPages = action.payload.totalPages;
  draft.getAll.list = action.payload.list;
}

export default getAllSuccessWithData;
