import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import helpers from 'helpers';
import IProduct from 'models/Product';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import ProductForm, { IProductFormData, IProductFormRefProps } from '../Form';

export interface IProductEditRefProps {
  open(data: IProduct): void;
}

const ProductEdit: React.ForwardRefRenderFunction<IProductEditRefProps> = (
  _,
  ref,
) => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(productSelectors.editIsLoading);

  const [hideModal, setHideModal] = useState<boolean>(false);
  const [productValues, setProductValues] = useState<IProduct>({} as IProduct);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const productFormRef = useRef<IProductFormRefProps>(null);

  const openModal = useCallback((data: IProduct) => {
    setHideModal(false);
    setProductValues(data);
    componentModalBaseRef.current?.open();

    setTimeout(() => {
      productFormRef.current?.formik.setValues({
        name: data.name,
        ean: data.barcode,
        amount: data.amount || '',
        description: data.description || '',
        manufacturer: {
          label: data.manufacturer.name,
          value: data.manufacturerId,
        },
        otherInfo: data.otherInfo || '',
        sku: data.sku,
        categories: data.categories?.map(category => ({
          label: category.name,
          value: category.id,
        })),
      });
      productFormRef.current?.setUploadedImages(data.images);
    }, 250);
  }, []);
  const closeModal = useCallback(() => {
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleEditProduct = useCallback(
    async (data: IProductFormData) => {
      productFormRef.current?.formik.setErrors({});
      reduxDispatch(
        productActions.editRequest({
          data: {
            id: productValues.id,
            name: data.name,
            ean: data.ean,
            images: productFormRef.current?.uploadedImages || [],
            manufacturerId: data.manufacturer?.value as number,
            sku: data.sku,
            amount: data.amount || undefined,
            description: data.description || undefined,
            otherInfo: data.otherInfo || undefined,
            categoryIds: data.categories
              ? data.categories.map(category => category.value)
              : [],
          },
          functions: {
            error: (err: any) => {
              helpers.errorHandling(err);
            },
            success: (message: string) => {
              toast.show({
                type: 'success',
                title: message,
              });
            },
            closeForm: () => {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, productValues, reduxDispatch, toast],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''} maxWidth={45}>
        <Title>Editar produto</Title>
        <ProductForm
          handleClose={closeModal}
          isLoading={isLoading}
          onSubmit={handleEditProduct}
          ref={productFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ProductEdit);
