import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';

import { BiCategory } from 'react-icons/bi';

import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';
import Forms from 'styles/forms';

export interface IFormData {
  name: string;
}

const initialValues: IFormData = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Informe um nome'),
});

interface IProps {
  isLoading: boolean;
  onClose(): void;
  onSubmit(data: IFormData): void;
}

const CategoryForm: ForwardRefRenderFunction<FormikProps<IFormData>, IProps> = (
  { isLoading, onClose, onSubmit },
  ref,
) => {
  const formikRef = useRef<FormikProps<IFormData>>(
    {} as FormikProps<IFormData>,
  );

  useImperativeHandle(ref, () => ({
    ...formikRef.current,
  }));

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, handleChange, values }) => (
        <Forms.Content>
          <Forms.Grid marginBottom="1">
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              icon={BiCategory}
              label="Nome da categoria"
              onChange={handleChange('name')}
              value={values.name}
            />
          </Forms.Grid>

          <Forms.Actions gridTemplateColumns="8rem 12rem">
            <ComponentButtonBase
              backgroundColor={colors.red500}
              disabled={isLoading}
              onClick={onClose}
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Confirmar
            </ComponentButtonBase>
          </Forms.Actions>
        </Forms.Content>
      )}
    </Formik>
  );
};

export default forwardRef(CategoryForm);
