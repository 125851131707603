import { useCallback, useEffect, useRef } from 'react';

import { FormikProps } from 'formik';
import helpers from 'helpers';
import ICategory from 'models/Category';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentHeader from 'components/Header';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import { categoryActions } from 'store/slices/category';
import categorySelectors from 'store/slices/category/selectors';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import CategoryCreate, {
  IRefProps as ICategoryCreateRefProps,
} from '../Create';
import CategoryEdit, { IRefProps as ICategoryEditRefProps } from '../Edit';
import CategoryFilter, { ICategoryFilterData } from './Filter';
import CategoryItem from './Item';

const CategoryList = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const categories = useReduxSelector(categorySelectors.getAllList);
  const pagination = useReduxSelector(categorySelectors.getAllPagination);
  const isLoading = useReduxSelector(categorySelectors.getAllIsLoading);
  const emptyMessage = useReduxSelector(categorySelectors.getAllEmptyMessage);
  const errorMessage = useReduxSelector(categorySelectors.getAllErrorMessage);

  const categoryFilterRef = useRef<FormikProps<ICategoryFilterData>>(null);
  const categoryEditRef = useRef<ICategoryEditRefProps>(null);
  const categoryCreateRef = useRef<ICategoryCreateRefProps>(null);

  const handleLoadCategories = useCallback(() => {
    reduxDispatch(
      categoryActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    page => {
      reduxDispatch(
        categoryActions.getAllRequest({
          data: {
            page,
            name: categoryFilterRef.current?.values.name,
          },
          functions: {
            error(err) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilter = useCallback(
    async (data: ICategoryFilterData) => {
      reduxDispatch(
        categoryActions.getAllRequest({
          data: {
            page: 1,
            name: data.name,
          },
          functions: {
            error(err: any) {
              helpers.errorHandling(err);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleClearFilter = useCallback(() => {
    categoryFilterRef.current?.resetForm();
    categoryFilterRef.current?.setErrors({});
    reduxDispatch(
      categoryActions.getAllRequest({
        data: {
          page: 1,
        },
        functions: {
          error(err: any) {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenEditCategory = useCallback((data: ICategory) => {
    categoryEditRef.current?.open(data);
  }, []);

  const handleOpenCreateCategory = useCallback(() => {
    categoryCreateRef.current?.open();
  }, []);

  useEffect(() => {
    handleLoadCategories();
  }, [handleLoadCategories]);

  return (
    <Containers.Global>
      <ComponentHeader pageTitle="Categorias" />
      <Containers.Content>
        <General.PageFilterContainer>
          <CategoryFilter
            isLoading={isLoading}
            onClear={handleClearFilter}
            onSubmit={handleFilter}
            ref={categoryFilterRef}
          />

          <General.PageActions gridTemplateColumns="10rem">
            <General.PageAction onClick={handleOpenCreateCategory}>
              Nova categoria
            </General.PageAction>
          </General.PageActions>
        </General.PageFilterContainer>

        <Tables.Items>
          <CategoryItem.Header />

          <ComponentLoadingList show={isLoading} />

          <ComponentIsVisible when={!isLoading}>
            <ComponentIsVisible when={!!categories?.length}>
              {categories?.map((category, position) => (
                <CategoryItem.Body
                  category={category}
                  key={category.id}
                  openEdit={handleOpenEditCategory}
                  position={position}
                />
              ))}
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={!categories?.length && !errorMessage}>
            <ComponentEmpty message={emptyMessage} show={!!emptyMessage} />
          </ComponentIsVisible>

          <ComponentIsVisible when={!!errorMessage}>
            <ComponentError
              message={errorMessage}
              onClick={handleLoadCategories}
            />
          </ComponentIsVisible>
        </Tables.Items>

        <CategoryCreate
          onReload={handleLoadCategories}
          ref={categoryCreateRef}
        />
        <CategoryEdit ref={categoryEditRef} />

        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show={!!categories?.length}
          totalPages={pagination.totalPages}
        />
      </Containers.Content>
    </Containers.Global>
  );
};

export default CategoryList;
